* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /*font-family: 'Inter', sans-serif;*/
  /*font-weight: normal;*/
  /*font-style: normal;*/
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Regular.otf) format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter-Medium.otf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'),
    url(./assets/fonts/Gilroy-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Bold.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url(./assets/fonts/Poppins-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url(./assets/fonts/Poppins-Light.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'),
    url(./assets/fonts/Poppins-SemiBold.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}
